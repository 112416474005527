import React from "react";

const NfcSVG = ({color}) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="50" height="50" fill={color} />
    <rect x="-211" y="-253" width="577" height="374" rx="20" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38.5638 19.5002C38.5638 30.0855 29.9825 38.6668 19.3971 38.6668C8.81167 38.6668 0.230469 30.0855 0.230469 19.5002C0.230469 8.9147 8.81167 0.333496 19.3971 0.333496C29.9825 0.333496 38.5638 8.9147 38.5638 19.5002ZM13.0119 11.714C12.9747 11.3604 12.7746 11.029 12.4431 10.8376C12.2402 10.7204 12.0165 10.6721 11.799 10.6855C11.5685 10.6715 11.3317 10.7266 11.121 10.8583C10.7873 11.0668 10.5975 11.4185 10.5814 11.7839C9.32358 14.1583 8.6638 16.8071 8.6638 19.5002C8.6638 22.3936 9.42543 25.236 10.8721 27.7418C11.1897 28.2919 11.893 28.4803 12.4431 28.1627C12.9931 27.8451 13.1816 27.1419 12.864 26.5918C11.6192 24.4358 10.9638 21.9899 10.9638 19.5002C10.9638 17.7265 11.2964 15.9752 11.9378 14.335L20.0323 27.2862C20.0695 27.6399 20.2698 27.9714 20.6012 28.1627C20.804 28.2798 21.0278 28.3281 21.2454 28.3149C21.4758 28.3289 21.7127 28.2737 21.9233 28.142C22.257 27.9335 22.4467 27.5818 22.4628 27.2165C23.7208 24.8421 24.3805 22.1933 24.3805 19.5002C24.3805 16.6068 23.6188 13.7643 22.1721 11.2585C21.8545 10.7085 21.1513 10.52 20.6012 10.8376C20.0511 11.1551 19.8627 11.8585 20.1803 12.4085C21.4252 14.5646 22.0805 17.0104 22.0805 19.5002C22.0805 21.2738 21.7479 23.0251 21.1066 24.6654L13.0119 11.714ZM27.0766 9.5335C26.7591 8.98345 26.9475 8.28013 27.4975 7.96256C28.0476 7.645 28.7509 7.83345 29.0684 8.3835C31.0198 11.7634 32.0471 15.5974 32.0471 19.5002C32.0471 23.4029 31.0198 27.237 29.0684 30.6168C28.7509 31.1669 28.0476 31.3553 27.4975 31.0377C26.9475 30.7201 26.7591 30.0169 27.0766 29.4668C28.8262 26.4366 29.7471 22.9992 29.7471 19.5002C29.7471 16.0011 28.8262 12.5637 27.0766 9.5335Z"
      fill={color}
    />
  </svg>
);

export default NfcSVG;
