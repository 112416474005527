import { createBrowserRouter, Navigate } from "react-router-dom";
import axios from "axios";
import Login from "./Pages/Login";
import ViewUsers from "./Pages/ViewUsers";
import AddUser from "./Pages/AddUser";
import ViewUser from "./Pages/ViewUser";
import EditUser from "./Pages/EditUser";
import ImageUploaderWithCrop from "./Pages/ImageUploaderWithCrop";

// axios.defaults.baseURL = "https://dev-api.adna.cards";
axios.defaults.baseURL = "https://api.adna.cards";

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
axios.defaults.headers.post["Accept"] = "application/json";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/ImageUploaderWithCrop",
    element: <ImageUploaderWithCrop />,
  },
  {
    path: "/view-users",
    element: <ViewUsers />,
  },
  {
    path: "/add-user",
    element: <AddUser />,
  },
  {
    path: "/view-user/:id",
    element: <ViewUser />,
  },
  {
    path: "/edit-user/:id",
    element: <EditUser />,
  },
]);

export default router;
