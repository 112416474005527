import React, { useEffect } from "react";
import "../styles/Chevalet.css";
import middle from "../assets/CHEVALET/middle.png";
import buildings from "../assets/CHEVALET/buildings.png";
import logoAdna from "../assets/CHEVALET/logoAdna.png";
import BlueWorks from "../assets/CHEVALET/BlueWorks.png";
import QRCodeStyling from "qr-code-styling";

const Chevalet = ({ user, croppedImage, subtitle }) => {
  const company_id = localStorage.getItem("company_id");
  const slug = localStorage.getItem("slug");

  console.log(subtitle);

  let isCompany;

  if (user.company && user.company.package_id == 1) {
    isCompany = `https://vcard.adna.cards/vcard/easel/qr/company/${company_id}`;
  } else {
    isCompany = `https://vcard.adna.cards/vcard/easel/qr/user/${slug}`;
  }

  console.log(isCompany);

  useEffect(() => {
    qrcodeChevalet.append(document.getElementById("qrcodeChevalet"));
  }, []);

  const qrcodeChevalet = new QRCodeStyling({
    width: 160,
    height: 160,
    type: "svg",
    data: `${isCompany}`,
    dotsOptions: {
      color: "white",
      type: "dots",
    },
    backgroundOptions: {
      color: "transparent",
    },
    cornersSquareOptions: {
      type: "dots",
    },
    cornersDotOptions: {
      type: "dot",
    },
  });

  console.log(user.title && user.title.length);
  console.log(user.company && user.company.name.length);
  // console.log(user.company && user.company.name.length);

  return (
    <div id="chevalet" className="cheval overflow-hidden">
      <div className="middleImage">
        <img src={middle} alt="" style={{ width: "554px", height: "604px" }} />
      </div>
      <div
        style={{
          position: "relative",
          textAlign: "center",
        }}
        className="justify-center"
      >
        <div className="absolute flex-col" style={{ width: "70%" }}>
          <div
            className="flex justify-center items-center logoCH"
            style={{ width: "132.5px", height: "121px" }}
          >
            {croppedImage && (
              <img
                src={croppedImage}
                className=" align-middle border-none"
                style={{ width: "130px", height: "120px", borderRadius: 200 }}
                // style={{ width: "132.5px", height: "121px" }}
              />
            )}
          </div>
          <p className="text-white font-poppins font-regular text-base scanMe">
            Scan me
          </p>

          <div className="qrCodeCh">
            <div
              id="qrcodeChevalet"
              style={{
                width: 160,
                height: 160,
                backgroundColor: "transparent",
                transform: "scale(1.3)",
              }}
            ></div>
          </div>

          <div className="flex flex-col  justify-center items-center nameSectionCh">
            {user.company && user.company.name.length < 20 ? (
              <p
                className="text-white font-poppins font-bold"
                style={{ fontSize: "21px", lineHeight: "22px" }}
              >
                {user.company && user.company.name}
              </p>
            ) : user.company && user.company.name.length < 30 ? (
              <p
                className="text-white font-poppins font-bold"
                style={{ fontSize: "19px", lineHeight: "22px" }}
              >
                {user.company && user.company.name}
              </p>
            ) : user.company && user.company.name.length < 40 ? (
              <p
                className="text-white font-poppins font-bold"
                style={{ fontSize: "18px", lineHeight: "22px" }}
              >
                {user.company && user.company.name}
              </p>
            ) : (
              <p
                className="text-white font-poppins font-bold"
                style={{
                  fontSize: "16px",
                  lineHeight: "22px",
                }}
              >
                {user.company && user.company.name}
              </p>
            )}

            {subtitle && subtitle.length < 35 ? (
              <p
                className="text-white font-poppins font-regular capitalize"
                style={{
                  fontSize: "15px",
                }}
              >
                {user.title}
              </p>
            ) : subtitle && subtitle.length < 70 ? (
              <p
                className="text-white font-poppins font-regular"
                style={{
                  fontSize: "14px",
                }}
              >
                {subtitle}
              </p>
            ) : subtitle && subtitle.length < 90 ? (
              <p
                className="text-white font-poppins font-regular"
                style={{
                  fontSize: "13px",
                }}
              >
                {subtitle}
              </p>
            ) : (
              <p
                className="text-white font-poppins font-regular capitalize"
                style={{
                  fontSize: "11px",
                }}
              >
                {subtitle}
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="bottomImage">
        <img
          src={buildings}
          alt=""
          style={{ width: "602px", height: "760px" }}
        />
      </div>
      <div
        style={{
          position: "relative",
          textAlign: "center",
        }}
      >
        <div className="absolute flex-col  adnaBrand">
          <img
            src={logoAdna}
            alt=""
            style={{ width: "60px", height: "31px" }}
          />
          <p
            className="text-black font-poppins font-bold"
            style={{
              fontSize: "12.5px",
              lineHeight: "29px",
            }}
          >
            www.adna.cards
          </p>
        </div>
      </div>
    </div>
  );
};

export default Chevalet;
