import axios from "axios";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import { MdOutlinePhoneAndroid, MdEmail, MdArrowBackIos } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { TbWorld } from "react-icons/tb";
import { FaUser } from "react-icons/fa6";

import QRCodeStyling from "qr-code-styling";
import { Box, Button, Modal } from "@mui/material";
import { useTranslation } from "react-i18next";

import jsPDF from "jspdf";
import PNUDCard from "../Components/PNUDCard";
import ALTECHSCard from "../Components/ALTECHSCard";
import STANDARDCard from "../Components/STANDARDCard";
import Chevalet from "./Chevalet";
import { CgSpinner } from "react-icons/cg";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import "react-easy-crop/react-easy-crop.css";
import { debounce } from "lodash";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  pt: 4,
  px: 6,
  pb: 3,
};

const ViewUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [user, setUser] = useState([]);
  const [logoModal, setLogoModal] = useState(false);
  const [getChevalet, setGetChevalet] = useState(false);
  const [loading, setLoading] = useState(false);
  const [picture, setPicture] = useState([]);
  const [file, setFile] = useState([]);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const inputRef = useRef(null);
  var presDate;
  var expi = 2;
  var title1;
  var title2;

  const [card_type, setCardType] = useState("Standard");
  const [color, setColor] = useState("#5C7BDF");
  const [colorModal, setColorModal] = useState(false);

  const handleClose = () => setLogoModal(false);
  function formatDate(date = new Date(), expi) {
    var day, month, year;
    if (expi === 2) {
      year = Math.floor(parseFloat(date.getFullYear())) + expi;
      month = date.getMonth() + 1;
      day = date.getDate();
    } else {
      year = date.getFullYear();
      month = date.getMonth() + 1;
      day = date.getDate();
    }

    if (month < 10) {
      month = "0" + month;
    }

    if (day < 10) {
      day = "0" + day;
    }

    return day + "/" + month + "/" + year;
  }
  const user_token = localStorage.getItem("user_token");
  const company_id = localStorage.getItem("company_id");

  useEffect(() => {
    if (!user_token) {
      navigate("/");
    }

    axios.get(`api/get-user/${id}`).then((res) => {
      if (res.data.status === 200) {
        setUser(res.data.user);
      }
    });

    if (card_type === "Standard") {
      setColor("#5C7BDF");
    } else if (user.company && user.company.primary_color !== null) {
      setColor(user.company.primary_color);
    }
  }, [id, card_type]);

  presDate = new Date(user.prestationDate);

  // Gestion du changement de couleur
  const handleChangeColor = (e) => {
    const newColor = e.target.value;
    setColor(newColor);
    saveColor(newColor); // Envoi la requête après un délai
  };

  const saveColor = (newColor) => {
    axios
      .post(`/api/web/store-color/${company_id}`, { color: newColor })
      .then((response) => {
        console.log("Couleur enregistrée avec succès");
      })
      .catch((error) => {
        console.error("Erreur lors de l'enregistrement", error);
      });
  };

  const generatePDF = async (fileName) => {
    const doc = new jsPDF("landscape", "px", [720, 450]);

    const frontCanvas = await html2canvas(document.getElementById("cardR"), {
      scale: 2,
    });
    const frontImgData = frontCanvas.toDataURL("image/png");
    doc.addImage(frontImgData, "PNG", 0, 0, 720, 450);

    doc.addPage();

    const backCanvas = await html2canvas(document.getElementById("cardV"), {
      scale: 2,
    });
    const backImgData = backCanvas.toDataURL("image/png");
    doc.addImage(backImgData, "PNG", 0, 0, 720, 450);

    doc.save(`${fileName}.pdf`);
  };

  const generatePDFChevalet = async (fileName) => {
    const doc = new jsPDF("portrait", "px", [2480, 3508]);

    const frontCanvas = await html2canvas(document.getElementById("chevalet"), {
      allowTaint: true,
      scale: 2,
      useCORS: true,
      backgroundColor: "white", // Pour garder le fond transparent
      logging: true,
    });
    const image = frontCanvas.toDataURL("image/png");
    doc.addImage(image, "PNG", 0, 0, 2480, 3508);

    doc.save(`${fileName}.pdf`);
  };

  const exportQrCode = async (imageFileName) => {
    const qrCode = document.getElementById("qrcodeChevalet");

    html2canvas(qrCode, {
      allowTaint: true,
      scale: 2,
      useCORS: true,
      backgroundColor: null, // Pour garder le fond transparent
      logging: true,
    }).then((canvas) => {
      const image = canvas.toDataURL("image/png", 1.0);
      downloadImage(image, imageFileName);
    });
  };

  const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
  };

  if (user.title && user.title.length > 39) {
    title1 =
      user.title.split(" ")[0] +
      " " +
      user.title.split(" ")[1] +
      " " +
      user.title.split(" ")[2] +
      " " +
      user.title.split(" ")[3];
    // console.log(user.title.split(" ")[0]);

    title2 =
      user.title.split(" ")[4] +
      " " +
      (user.title.split(" ")[5] !== undefined
        ? user.title.split(" ")[5]
        : " ") +
      " " +
      (user.title.split(" ")[6] !== undefined
        ? user.title.split(" ")[6]
        : " ") +
      " " +
      (user.title.split(" ")[7] !== undefined
        ? user.title.split(" ")[7]
        : " ") +
      " " +
      (user.title.split(" ")[8] !== undefined
        ? user.title.split(" ")[8]
        : " ") +
      " " +
      (user.title.split(" ")[9] !== undefined
        ? user.title.split(" ")[9]
        : " ") +
      " " +
      (user.title.split(" ")[10] !== undefined
        ? user.title.split(" ")[10]
        : " ");
  }

  let subtitle;

  if (user.company && user.company.package_id == 1) {
    subtitle = user.company.slogan;
  } else {
    subtitle = user.title;
  }

  const handleImage = (e) => {
    e.persist();
    setPicture({ image: e.target.files[0] });
  };

  // Fonction pour gérer l'upload du fichier image
  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result); // Affiche l'image dans le cropper
      };
      reader.readAsDataURL(file);
    }
  };
  // Récupère les pixels de la zone croppée
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // Fonction pour générer l'image croppée
  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (err) => reject(err));
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, croppedAreaPixels) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;

    ctx.drawImage(
      image,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      croppedAreaPixels.width,
      croppedAreaPixels.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        const url = URL.createObjectURL(blob);
        resolve(url);
      }, "image/jpeg");
    });
  };

  // Gérer la génération de l'image croppée
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImageUrl = await getCroppedImg(imageSrc, croppedAreaPixels);
      setCroppedImage(croppedImageUrl); // Affiche l'image croppée

      // const data = {
      //   image: croppedImage,
      // };

      // console.log(data);

      // axios.post(`api/commercial/update-logoComp/${user.company_id}`, data).then((res) => {
      //   if (res.data.status === 200) {
      //     console.log(res.data.message);
      //   }
      // });
      handleClose();
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels]);

  return (
    <div className="bg-slate-50  flex ">
      {/* sibebar  */}
      <div className="flex flex-col font-poppins bg-clip-border rounded-xl bg-white h-full w-full max-w-[20rem]  p-4 shadow-xl shadow-blue-gray-900/5 fixed">
        <div className="flex flex-wrap justify-center mt-10">
          <div className="flex w-full">
            <div className="ml-4 mt-12 pr-12 cursor-pointer">
              <MdArrowBackIos
                color="#9e9e9e"
                size={25}
                onClick={() => navigate("/view-users")}
              />
            </div>
            <div className="flex justify-center items-center w-28">
              {user.image !== null ? (
                <img
                  src={`https://api.adna.cards/public/storage/user_images/${user.profile_photo_path}`}
                  alt=""
                  className="shadow rounded-full w-28 h-28 align-middle border-none"
                />
              ) : (
                <span className="inline-block p-7 rounded-full bg-gray-100">
                  <FaUser color="#727565" size={40} />
                </span>
              )}
            </div>
          </div>
          <div className="mt-6">
            <div className="text-center ">
              <h2 className="text-black text-xl font-bold w-80 ">
                {user.firstname} {user.lastname}
              </h2>
              <p className="text-gray-400 text-xs">{user.title}</p>
              <p className="text-gray-400 text-xs">{user.slug}</p>
            </div>
          </div>
          <div className="flex flex-row w-full mt-14 rounded-md p-3 bg-white shadow text-gray-500">
            <div className="pr-4">
              <MdOutlinePhoneAndroid color="#9e9e9e" size={18} />
            </div>
            <div className="text-sm">
              <p className="font-semibold">
                {user.phone}
                {user.phone2 !== null ? "/" + user.phone2 : ""}
              </p>
            </div>
          </div>
          <div className="flex flex-row w-full mt-5 rounded-md p-3 bg-white shadow text-gray-500">
            <div className="pr-4">
              <MdEmail color="#9e9e9e" size={18} />
            </div>
            <div className="text-sm">
              {user.email !== "" ? (
                <p className="font-semibold">{user.email}</p>
              ) : (
                <p className="font-semibold">{t("description.email")}</p>
              )}
            </div>
          </div>
          <div className="flex flex-row w-full mt-5 rounded-md p-3 bg-white shadow text-gray-500">
            <div className="pr-4">
              <TbWorld color="#9e9e9e" size={18} />
            </div>
            <div className="text-sm">
              <p className="font-semibold">
                {user.company && user.company.website !== null
                  ? user.company.website
                  : t("description.website")}
              </p>
            </div>
          </div>
          <div className="flex flex-row w-full mt-5 rounded-md p-3 bg-white shadow text-gray-500">
            <div className="pr-4">
              <FaLocationDot color="#9e9e9e" size={18} />
            </div>
            <div className="text-sm">
              <p className="font-semibold">
                {user.city}, {user.address}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Vcard */}
      <div className="h-full w-full mt-[3rem] pb-44">
        <div className="flex justify-end mr-[3rem] mb-[3rem] ">
          {!getChevalet ? (
            <div className="flex gap-4">
              <label className="relative block">
                <select
                  className="w-full py-2 px-4 rounded-md shadow-sm focus:outline-none bg-base-invers focus:border-indigo-400 text-muted-2"
                  name="card_type"
                  onChange={(e) => setCardType(e.currentTarget.value)}
                  value={card_type}
                >
                  <option value="Standard">Standard</option>
                  <option value="Pro">Professionel</option>
                </select>
              </label>
              {card_type && card_type == "Pro" ? (
                <>
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      textTransform: "capitalize",
                      backgroundColor: "rgb(129 140 248)",
                    }}
                    onClick={() => setColorModal(true)}
                  >
                    Définir une couleur
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      textTransform: "capitalize",
                      backgroundColor: "rgb(129 140 248)",
                    }}
                    onClick={() => setLogoModal(true)}
                  >
                    Ajouter une image
                  </Button>
                </>
              ) : (
                ""
              )}

              <Button
                variant="contained"
                size="small"
                style={{
                  textTransform: "capitalize",
                  backgroundColor: "rgb(129 140 248)",
                }}
                onClick={() => setGetChevalet(true)}
              >
                Obtenir le chevalet
              </Button>
            </div>
          ) : (
            <div className="flex gap-4">
              <Button
                onClick={() => setLogoModal(true)}
                variant="contained"
                size="small"
                style={{
                  textTransform: "capitalize",
                  backgroundColor: "rgb(129 140 248)",
                }}
              >
                Ajouter un Logo
              </Button>

              <Button
                onClick={() =>
                  exportQrCode(`QrCode_${user.firstname + user.lastname}`)
                }
                variant="contained"
                size="small"
                style={{
                  textTransform: "capitalize",
                  backgroundColor: "rgb(129 140 248)",
                }}
              >
                Télécharger le qrCode
              </Button>
              <Button
                variant="contained"
                size="small"
                style={{
                  textTransform: "capitalize",
                  backgroundColor: "rgb(129 140 248)",
                }}
                onClick={() => setGetChevalet(false)}
              >
                Obtenir la card
              </Button>
            </div>
          )}
        </div>

        <div className="flex gap-2 flex-col items-center justify-center ml-[17rem]">
          {!getChevalet ? (
            <>
              {user.company && user.company.id === 21 ? (
                <PNUDCard
                  user={user}
                  title1={title1 && title1}
                  title2={title2 && title2}
                />
              ) : user.company && user.company.id === 1 ? (
                <ALTECHSCard user={user} />
              ) : (
                <STANDARDCard
                  user={user}
                  color={color}
                  croppedImage={croppedImage}
                  card_type={card_type}
                />
              )}

              <Button
                onClick={() =>
                  card_type && card_type === "Pro"
                    ? generatePDF(
                        `Card_Pro_${user.firstname + " " + user.lastname}`
                      )
                    : generatePDF(
                        `Card_Standard_${user.firstname + " " + user.lastname}`
                      )
                }
                variant="contained"
                size="small"
                style={{
                  backgroundColor: "rgb(129 140 248)",
                  margin: "6px",
                  textTransform: "capitalize",
                }}
              >
                Télécharger
              </Button>

              {/* Open Color Modal  */}
              <Modal open={colorModal} onClose={() => setColorModal(false)}>
                <Box sx={style}>
                  <div className="flex flex-col items-center justify-center">
                    <label
                      for="hs-color-input"
                      class="block text-xl font-regular mt-2 text-black"
                    >
                      Choisir une couleur
                    </label>
                    <input
                      type="color"
                      class="p-1 h-10 w-14 block bg-white cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none"
                      value={color}
                      onChange={handleChangeColor}
                      title="Choose your color"
                    ></input>
                  </div>
                </Box>
              </Modal>
              {/* End Color Modal  */}
            </>
          ) : (
            <>
              <Chevalet
                user={user}
                croppedImage={croppedImage}
                subtitle={subtitle}
              />
              <div>
                <Button
                  onClick={() =>
                    generatePDFChevalet(`Chevalet_${user.company.name}`)
                  }
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "rgb(129 140 248)",
                    marginTop: "6px",
                    marginBottom: "25px",
                    textTransform: "capitalize",
                  }}
                >
                  Télécharger
                </Button>
              </div>
            </>
          )}
        </div>
        {/* Open Logo Modal  */}
        <Modal
          open={logoModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <label className="block">
              <span className="sr-only">Choisir une image</span>
              <input
                type="file"
                accept="image/*"
                ref={inputRef}
                onChange={onFileChange}
                className="block w-full text-sm text-gray-600
      file:mr-4 file:py-2 file:px-4
      file:rounded-md file:border-0
      file:text-sm file:text-gray-600 file:font-semibold
      file:cursor-pointer file:bg-gray-100
      "
              />
            </label>

            {imageSrc && (
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: 400,
                }}
              >
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  zoom={zoom}
                  aspect={1} // Carré
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                />
              </div>
            )}

            {imageSrc && (
              <div style={{ marginTop: "20px" }}>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(zoom)}
                  style={{
                    color: "rgb(129 140 248)",
                  }}
                />
                <div className="flex justify-center">
                  <button
                    onClick={showCroppedImage}
                    className="text-sm cursor-pointer text-white mt-2 block border-0 p-2 rounded bg-indigo-400"
                  >
                    Génerer une image
                  </button>
                </div>
              </div>
            )}
          </Box>
        </Modal>
        {/* End Logo Modal  */}
      </div>
    </div>
  );
};

export default ViewUser;
