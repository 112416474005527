import React, { useState, useRef, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import 'react-easy-crop/react-easy-crop.css';

const ImageUploaderWithCrop = () => {
  const [imageSrc, setImageSrc] = useState(null); // Image source après upload
  const [crop, setCrop] = useState({ x: 0, y: 0 }); // Position du crop
  const [zoom, setZoom] = useState(1); // Niveau de zoom
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null); // Stocke les coordonnées du crop
  const [croppedImage, setCroppedImage] = useState(null); // URL de l'image croppée
  const inputRef = useRef(null);

  // Fonction pour gérer l'upload du fichier image
  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result); // Affiche l'image dans le cropper
      };
      reader.readAsDataURL(file);
    }
  };

  // Récupère les pixels de la zone croppée
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // Fonction pour générer l'image croppée
  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (err) => reject(err));
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, croppedAreaPixels) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;

    ctx.drawImage(
      image,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      croppedAreaPixels.width,
      croppedAreaPixels.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        const url = URL.createObjectURL(blob);
        resolve(url);
      }, 'image/jpeg');
    });
  };

  // Gérer la génération de l'image croppée
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImageUrl = await getCroppedImg(imageSrc, croppedAreaPixels);
      setCroppedImage(croppedImageUrl); // Affiche l'image croppée
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels]);

  return (
    <div>
      <h2>Uploader et Cropper une Image</h2>
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        onChange={onFileChange}
      />

      {imageSrc && (
        <div style={{ position: 'relative', width: '100%', height: 400 }}>
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={1} // Carré
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </div>
      )}

      {imageSrc && (
        <div style={{ marginTop: '20px' }}>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => setZoom(zoom)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={showCroppedImage}
            style={{ marginTop: '20px' }}
          >
            Générer Image Croppée
          </Button>
        </div>
      )}

      {croppedImage && (
        <div style={{ marginTop: '20px' }}>
          <h3>Image Croppée :</h3>
          <img src={croppedImage} alt="Cropped" style={{ maxWidth: '100%' }} />
        </div>
      )}
    </div>
  );
};

export default ImageUploaderWithCrop;
