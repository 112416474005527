import React from "react";

const UserSVG = ({color}) => (
  <svg
    width="57"
    height="57"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22.5" cy="22.5" r="22.5" fill={color} fillOpacity="0.18" />
    <mask
      id="mask0_2443_3166"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="57"
      height="57"
    >
      <circle cx="22.5" cy="22.5" r="22.5" fill={color} />
    </mask>
    <g mask="url(#mask0_2443_3166)">
      <path
        d="M38.7941 43.5712C39.9593 43.3284 40.6559 42.1084 40.0754 41.0656C38.7962 38.7711 36.7825 36.7532 34.2073 35.2165C30.889 33.2365 26.8215 32.1621 22.642 32.1621C18.4625 32.1621 14.3949 33.2344 11.0767 35.2165C8.50149 36.7532 6.48775 38.7711 5.20858 41.0656C4.6281 42.1084 5.32468 43.3284 6.48986 43.5712C17.1428 45.7915 28.1391 45.7915 38.792 43.5712"
        fill={color}
      />
      <path
        opacity="0.6"
        d="M22.6411 30.0513C28.47 30.0513 33.1953 25.326 33.1953 19.4971C33.1953 13.6681 28.47 8.94287 22.6411 8.94287C16.8122 8.94287 12.0869 13.6681 12.0869 19.4971C12.0869 25.326 16.8122 30.0513 22.6411 30.0513Z"
        fill={color}
      />
    </g>
  </svg>
);

export default UserSVG;
