import React, { useEffect, useState } from "react";
import userIcon from "../assets/STANDARD/userIcon.png";
import logoAdna from "../assets/STANDARD/logoAdna.png";
import NFC from "../assets/STANDARD/NFC.png";
import QRCodeStyling from "qr-code-styling";
import { useParams } from "react-router-dom";
import "../styles/STANDARDCard.css";
import ColorBack from "../assets/STANDARD/ColorBack.png";
import Vector from "../assets/STANDARD/Vector.png";
import UserSVG from "../SvgComponents/UserSVG";
import NfcSVG from "../SvgComponents/NfcSVG";
import ColorBackSVG from "../SvgComponents/ColorBackSVG";

const STANDARDCard = ({ user, color, croppedImage, card_type }) => {
  const dataR = localStorage.getItem("slug");
  const dataV = localStorage.getItem("vcard");
  const { id } = useParams();
  // console.log(dataV && dataV.length);

  useEffect(() => {
    qrCodeR.append(document.getElementById("qrcodeR"));
    qrCodeV.append(document.getElementById("qrcodeV"));
    console.log(card_type);
  }, [id]);

  const qrCodeR = new QRCodeStyling({
    width: 140,
    height: 140,
    type: "svg",
    data: `https://vcard.adna.cards/vcard/card/qr/${dataR}`,
    dotsOptions: {
      color: "black",
      type: "dots",
    },
    backgroundOptions: {
      color: "#ffffff",
    },
    cornersSquareOptions: {
      type: "dots",
    },
    cornersDotOptions: {
      type: "dot",
    },
  });

  const qrCodeV = new QRCodeStyling(
    dataV && dataV.length < 468
      ? {
          width: 200,
          height: 200,
          margin: 3,
          type: "svg",
          data: dataV,
          dotsOptions: {
            color: "black",
            type: "dots",
          },
          backgroundOptions: {
            color: "white",
            borderRadius: 5,
          },
          cornersSquareOptions: {
            type: "dots",
          },
          cornersDotOptions: {
            type: "dot",
          },
        }
      : dataV && dataV.length < 480
      ? {
          width: 205,
          height: 205,
          margin: 3,
          type: "svg",
          data: dataV,
          dotsOptions: {
            color: "black",
            type: "dots",
          },
          backgroundOptions: {
            color: "white",
            borderRadius: 5,
          },
          cornersSquareOptions: {
            type: "dots",
          },
          cornersDotOptions: {
            type: "dot",
          },
        }
      : dataV && dataV.length < 558
      ? {
          width: 218,
          height: 218,
          margin: 3,
          type: "svg",
          data: dataV,
          dotsOptions: {
            color: "black",
            type: "dots",
          },
          backgroundOptions: {
            color: "white",
            borderRadius: 5,
          },
          cornersSquareOptions: {
            type: "dots",
          },
          cornersDotOptions: {
            type: "dot",
          },
        }
      : dataV && dataV.length < 650
      ? {
          width: 235,
          height: 235,
          margin: 3,
          type: "svg",
          data: dataV,
          dotsOptions: {
            color: "black",
            type: "dots",
          },
          backgroundOptions: {
            color: "white",
            borderRadius: 5,
          },
          cornersSquareOptions: {
            type: "dots",
          },
          cornersDotOptions: {
            type: "dot",
          },
        }
      : dataV && dataV.length < 740
      ? {
          width: 250,
          height: 250,
          margin: 3,
          type: "svg",
          data: dataV,
          dotsOptions: {
            color: "black",
            type: "dots",
          },
          backgroundOptions: {
            color: "white",
            borderRadius: 5,
          },
          cornersSquareOptions: {
            type: "dots",
          },
          cornersDotOptions: {
            type: "dot",
          },
        }
      : {
          width: 260,
          height: 260,
          margin: 3,
          type: "svg",
          data: dataV,
          dotsOptions: {
            color: "black",
            type: "dots",
          },
          backgroundOptions: {
            color: "white",
            borderRadius: 5,
          },
          cornersSquareOptions: {
            type: "dots",
          },
          cornersDotOptions: {
            type: "dot",
          },
        }
  );

  return (
    <>
      <div id="cardR" className="backImgST">
        <div className="userIconST">
          {/* <img src={userIcon} alt="" style={{ width: "57px" }} /> */}
          <UserSVG color={color} />
        </div>
        <div className="flex flex-col nameSectionST ">
          {user.firstname &&
          user.lastname &&
          user.lastname.length + user.firstname.length < 30 ? (
            <p
              className="text-black font-poppins font-extrabold"
              style={{ fontSize: "27px", lineHeight: "36px" }}
            >
              {/* DIKOUM Eveline */}
              {user.firstname} {user.lastname}
            </p>
          ) : user.firstname &&
            user.lastname &&
            user.lastname.length + user.firstname.length < 38 ? (
            <p
              className="text-black font-poppins font-extrabold"
              style={{ fontSize: "24px", lineHeight: "36px" }}
            >
              {user.firstname} {user.lastname}
            </p>
          ) : user.firstname &&
            user.lastname &&
            user.lastname.length + user.firstname.length < 40 ? (
            <p
              className="text-black font-poppins font-extrabold"
              style={{ fontSize: "21px", lineHeight: "36px" }}
            >
              {user.firstname} {user.lastname}
            </p>
          ) : user.firstname &&
            user.lastname &&
            user.lastname.length + user.firstname.length < 45 ? (
            <p
              className="text-black font-poppins font-extrabold"
              style={{ fontSize: "19px", lineHeight: "36px" }}
            >
              {user.firstname} {user.lastname}
            </p>
          ) : (
            <p
              className="text-black font-poppins font-extrabold"
              style={{
                fontSize: "18px",
                lineHeight: "36px",
                maxWidth: "70%",
              }}
            >
              {user.firstname} {user.lastname}
            </p>
          )}

          {user.title && user.title.length < 58 ? (
            <p
              className="text-black font-poppins font-regular"
              style={{
                fontSize: "20px",
                lineHeight: "18px",
              }}
            >
              {user.title}
              {/* Consultante En Réseau Informatique */}
            </p>
          ) : (
            <p
              className="text-black font-poppins font-regular"
              style={{
                fontSize: "17px",
                lineHeight: "18px",
                maxWidth: "70%",
              }}
            >
              {user.title}
            </p>
          )}
        </div>
        {card_type && card_type === "Standard" ? (
          <>
            <div
              className={
                user.title && user.title.length < 70
                  ? "logoAdnaST"
                  : "logoAdnaST2"
              }
            >
              <img src={logoAdna} alt="" style={{ width: "116px" }} />
            </div>
            <div className="text-black flex flex-row  items-center ">
              <div
                className={
                  user.title && user.title.length < 70
                    ? "smartCardST"
                    : "smartCardST2"
                }
              >
                <NfcSVG color={color} />
              </div>
              <div
                className={
                  user.title && user.title.length < 70
                    ? "smartCardST"
                    : "smartCardST2"
                }
              >
                <p
                  className="text-black font-bold font-harabara"
                  style={{
                    fontSize: "20px",
                    lineHeight: "15px",
                    letterSpacing: "1px",
                    marginLeft: "10px",
                  }}
                >
                  Smart
                  <br />
                  <span style={{ color: color }}>Business</span>
                  <br />
                  Card
                </p>
              </div>
            </div>
            <div
              id="qrcodeR"
              className={
                user.title && user.title.length < 70
                  ? "flex justify-center qrcodeST"
                  : "flex justify-center qrcodeST2"
              }
              style={{
                width: 140,
                height: 140,
                backgroundColor: "rgb(255 255 255)",
              }}
            ></div>
          </>
        ) : (
          <>
            <div
              className={
                user.title && user.title.length < 70
                  ? "logoAdnaSTCrop"
                  : "logoAdnaST2"
              }
            >
              {croppedImage && (
                <img
                  src={croppedImage}
                  className=" align-middle border-none"
                  style={{ width: "115px", borderRadius: 200 }}
                />
              )}
            </div>
            <div className="text-black flex flex-row  items-center ">
              <div
                className={
                  user.title && user.title.length < 70
                    ? "smartCardSTCrop"
                    : "smartCardST2"
                }
              >
                <NfcSVG color={color} />
              </div>
              <div
                className={
                  user.title && user.title.length < 70
                    ? "smartCardSTCrop"
                    : "smartCardST2"
                }
              >
                <p
                  className="text-black font-bold font-harabara"
                  style={{
                    fontSize: "20px",
                    lineHeight: "15px",
                    letterSpacing: "1px",
                    marginLeft: "10px",
                  }}
                >
                  Digital
                  <br />
                  <span style={{ color: color }}>Business</span>
                  <br />
                  Card
                </p>
              </div>
            </div>
            <div
              id="qrcodeR"
              className={
                user.title && user.title.length < 70
                  ? "flex justify-center qrcodeSTCrop"
                  : "flex justify-center qrcodeST2"
              }
              style={{
                width: 140,
                height: 140,
                backgroundColor: "rgb(255 255 255)",
              }}
            ></div>
          </>
        )}
      </div>

      <br />
      <div id="cardV" className="flex backImgVersoST">
        <div className="m-auto">
          <div className="flex flex-col justify-center items-center space-y-16 mt-20">
            <div
              id="qrcodeV"
              style={
                dataV && dataV.length < 468
                  ? {
                      width: 200,
                      height: 200,
                      backgroundColor: "white",
                      transform: "scale(1.5)",
                    }
                  : dataV && dataV.length < 480
                  ? {
                      width: 205,
                      height: 205,
                      backgroundColor: "white",
                      transform: "scale(1.5)",
                    }
                  : dataV && dataV.length < 558
                  ? {
                      width: 218,
                      height: 218,
                      backgroundColor: "white",
                      transform: "scale(1.5)",
                    }
                  : dataV && dataV.length < 650
                  ? {
                      width: 235,
                      height: 235,
                      backgroundColor: "white",
                      transform: "scale(1.5)",
                    }
                  : dataV && dataV.length < 740
                  ? {
                      width: 250,
                      height: 250,
                      backgroundColor: "white",
                      transform: "scale(1.5)",
                    }
                  : {
                      width: 260,
                      height: 260,
                      backgroundColor: "white",
                      transform: "scale(1.5)",
                    }
              }
            ></div>
            <p className="text-black  font-poppins font-medium text-lg ">
              Scan me
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default STANDARDCard;
